import React, { useState, useMemo } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  FormGroup,
  Label,
} from "reactstrap"
import { addWarehouseStock } from "../../../store/warehouse/actions"

const AddItemWarehouseStockModal = ({
  isOpen,
  toggle,
  goods,
  selectedWarehouseId,
}) => {
  const [selectedGood, setSelectedGood] = useState(null)
  const [stock_count, setStock_count] = useState(0)
  const [searchQuery, setSearchQuery] = useState("")
  const [sortConfig, setSortConfig] = useState({
    key: "goods_id",
    direction: "ascending",
  })

  const dispatch = useDispatch()

  const handleAddItem = () => {
    const newItem = {
      warehouse_id: selectedWarehouseId, // 必要な倉庫IDを追加
      goods_id: selectedGood.goods_id,
      stock_count: stock_count ? stock_count : 0,
    }
    dispatch(addWarehouseStock(newItem))

    toggle() // モーダルを閉じる
  }

  const handleSort = key => {
    let direction = "ascending"
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending"
    }
    setSortConfig({ key, direction })
  }

  const sortedGoods = useMemo(() => {
    let sortableGoods = [...goods]
    sortableGoods.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1
      }
      return 0
    })
    return sortableGoods
  }, [goods, sortConfig])

  const filteredGoods = sortedGoods.filter(
    good =>
      good.goods_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      good.company_name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>倉庫在庫に新規商品を追加</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="search">商品検索</Label>
          <Input
            id="search"
            type="text"
            placeholder="商品名または会社名で検索"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </FormGroup>
        <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
          <Table className="table">
            <thead>
              <tr>
                <th onClick={() => handleSort("goods_id")}>ID</th>
                <th onClick={() => handleSort("goods_name")}>商品名</th>
                <th onClick={() => handleSort("company_name")}>会社名</th>
                <th>選択</th>
              </tr>
            </thead>
            <tbody>
              {filteredGoods.map(good => (
                <tr key={good.goods_id}>
                  <td>{good.goods_id}</td>
                  <td>{good.goods_name}</td>
                  <td>{good.company_name}</td>
                  <td>
                    <Input
                      type="radio"
                      name="selectedGood"
                      value={good.goods_id}
                      onChange={() => setSelectedGood(good)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <FormGroup>
          <Label for="stock_count">数量</Label>
          <Input
            id="stock_count"
            type="number"
            value={stock_count}
            onChange={e => setStock_count(parseInt(e.target.value, 10) || 0)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          キャンセル
        </Button>
        <Button color="primary" onClick={handleAddItem}>
          追加
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddItemWarehouseStockModal
