// actions.js
import {
  FETCH_GOODS_BRAND_GRAPH_DATA,
  FETCH_GOODS_BRAND_GRAPH_DATA_SUCCESS,
  FETCH_GOODS_BRAND_GRAPH_DATA_ERROR,
  FETCH_GOODS_BRAND_REG_DATA,
  FETCH_GOODS_BRAND_REG_DATA_SUCCESS,
  FETCH_GOODS_BRAND_REG_DATA_ERROR,
} from "./actionTypes"

// Fetch graph data action
export const fetchGraphData = (goodsBrand, ipcs, timePeriod, currentDate) => {
  return {
    type: FETCH_GOODS_BRAND_GRAPH_DATA,
    payload: { goodsBrand, ipcs, timePeriod, currentDate },
  }
}

// Fetch graph data success action
export const fetchGraphDataSuccess = payload => {
  return {
    type: FETCH_GOODS_BRAND_GRAPH_DATA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const fetchGraphDataError = error => {
  return {
    type: FETCH_GOODS_BRAND_GRAPH_DATA_ERROR,
    error,
  }
}
// Fetch graph data action
export const fetchGoodsBrandRegData = (
  targetGoods,
  brand,
  brandName,
  deleteFlg,
  createFlg,
) => {
  return {
    type: FETCH_GOODS_BRAND_REG_DATA,
    payload: { targetGoods, brand, brandName, deleteFlg, createFlg },
  }
}

// Fetch graph data success action
export const fetchGoodsBrandRegDataSuccess = payload => {
  return {
    type: FETCH_GOODS_BRAND_REG_DATA_SUCCESS,
    payload,
  }
}

// Fetch graph data error action
export const fetchGoodsBrandRegDataError = error => {
  return {
    type: FETCH_GOODS_BRAND_REG_DATA_ERROR,
    error,
  }
}
