import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from "reactstrap"
import {
  fetchGoodsDetail,
  registerSurprise,
  unregisterSurprise,
  fetchGoods,
} from "../../../store/goods/actions"
import ErrorMessage from "../../Common/ErrorMessage"
import LoadingOverlay from "../../Common/LoadingOverlay"

const SurpriseModal = ({ isOpen, toggle, item, onRegisterSurprise }) => {
  const dispatch = useDispatch()

  const [selectedUnassignedItem, setSelectedUnassignedItem] = useState(null)
  const [selectedAssignedItem, setSelectedAssignedItem] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")

  // 状態を取得し、デフォルト値を設定
  const goodsDetail = useSelector(state => state.GoodsReducer.goodsDetail) || {
    result1: [],
    result2: [],
  }
  const isLoading = useSelector(state => state.GoodsReducer.loading)
  const error = useSelector(state => state.GoodsReducer.error)

  const result1 = goodsDetail.result1 || []
  const result2 = goodsDetail.result2 || []

  useEffect(() => {
    if (item && item.goods_id) {
      dispatch(fetchGoodsDetail(item.goods_id))
    }
  }, [dispatch, item])

  useEffect(() => {
    dispatch(fetchGoods())
  }, [goodsDetail])

  const searchTerms = searchTerm.split(/[\s\u3000]+/) // 半角スペースおよび全角スペースで分割

  const filteredPotentialItems = result1.filter(item =>
    searchTerms.every(
      term =>
        item.goods_name.toLowerCase().includes(term.toLowerCase()) ||
        item.goods_no.toLowerCase().includes(term.toLowerCase())
    )
  )

  const registeredItems = result2

  const handleRegister = () => {
    if (selectedUnassignedItem && item && item.goods_id) {
      dispatch(registerSurprise(item.goods_id, selectedUnassignedItem.goods_id))
      setSelectedUnassignedItem(null)
    }
  }

  const handleUnregister = () => {
    if (selectedAssignedItem) {
      dispatch(unregisterSurprise(item.goods_id, selectedAssignedItem.goods_id))
      setSelectedAssignedItem(null)
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="custom-modal">
      <ErrorMessage />
      <LoadingOverlay isLoading={isLoading} />
      <ModalHeader toggle={toggle}>
        サプライズ商品登録
        {item && item.goods_name ? `: ${item.goods_no}_${item.goods_name}` : ""}
      </ModalHeader>
      <ModalBody style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flex: 1, marginRight: "10px" }}>
          <h5>商品一覧</h5>
          <div>
            <Input
              type="text"
              placeholder="商品名で検索..."
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            <ul style={{ padding: 0, listStyleType: "none" }}>
              {filteredPotentialItems.map(goods => (
                <li
                  key={goods.goods_id}
                  onClick={() => setSelectedUnassignedItem(goods)}
                  style={{
                    backgroundColor:
                      selectedUnassignedItem &&
                      selectedUnassignedItem.goods_id === goods.goods_id
                        ? "lightgray"
                        : "transparent",
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  {goods.goods_no} : {goods.goods_name || "商品名無し"}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
          }}
        >
          <button
            onClick={handleRegister}
            disabled={!selectedUnassignedItem}
            style={{ marginBottom: "10px", width: "50px" }}
          >
            &gt;
          </button>
          <button
            onClick={handleUnregister}
            disabled={!selectedAssignedItem}
            style={{ marginBottom: "10px", width: "50px" }}
          >
            &lt;
          </button>
        </div>
        <div style={{ flex: 1, marginLeft: "10px" }}>
          <h5>登録された商品</h5>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            <ul style={{ padding: 0, listStyleType: "none" }}>
              {registeredItems.map(goods => (
                <li
                  key={goods.goods_id}
                  onClick={() => setSelectedAssignedItem(goods)}
                  style={{
                    backgroundColor:
                      selectedAssignedItem &&
                      selectedAssignedItem.goods_id === goods.goods_id
                        ? "lightgray"
                        : "transparent",
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  {goods.goods_name || "商品名無し"}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          閉じる
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SurpriseModal
