import React, { useState, useEffect, useMemo } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import Select from "react-select"
import { ListGroup, ListGroupItem } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle } from "reactstrap"
import {
  fetchGraphData,
  fetchGoodsBrandRegData,
} from "../../store/brands/actions"
import { fetchGoodsBrandData } from "../../store/marketing/common/actions"

const GoodList = () => {
  const [newGood, setNewGood] = useState({ name: "", brand: null })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedGoods, setSelectedGoods] = useState({})
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [searchCriteria, setSearchCriteria] = useState("")
  const [editingProduct, setEditingProduct] = useState(null)
  const [editingBrand, setEditingBrand] = useState(null)
  const [editedBrandName, setEditedBrandName] = useState("")
  const [newBrand, setNewBrand] = useState("")

  const dispatch = useDispatch()

  const graphData = useSelector(state => state.BrandsReducer.graphData)
  const goods = graphData && graphData.data ? graphData.data.goodsResults : []
  const goodsBrandData =
    graphData && graphData.data ? graphData.data.goodsBrandResults : []

  // TODO バックエンドで商品と商品ブランド両方返す、値を分けて、BrandsReducerに格納する

  const isLoading = useSelector(state => state.BrandsReducer.loading)

  let filteredGoods = goods.filter(good =>
    good && searchCriteria
      ? (good.goods_name ? good.goods_name.includes(searchCriteria) : false) ||
        (good.goods_brand_id
          ? good.goods_brand_id.toString().includes(searchCriteria)
          : false)
      : true
  )

  const goodsBrandOptions = useMemo(() => {
    if (!goodsBrandData) return []

    return [
      ...goodsBrandData.map(item => ({
        label: item.goods_brand_name,
        value: item.goods_brand_id,
      })),
    ]
  }, [goodsBrandData])

  const handleNewGoodChange = selectedOption => {
    setNewGood({
      ...newGood,
      name: selectedOption.label,
      brand: selectedOption.value,
    })
  }

  const handleBrandRegistration = () => {
    // Open the confirmation modal
    console.log("Registering brand: ", newBrand)

    // 対象商品ID配列、対象ブランドID,ブランド名、削除フラグ、登録フラグ
    dispatch(fetchGoodsBrandRegData(null, null, newBrand, null, true))
  }

  const handleBrandAllRegistration = () => {
    setIsConfirmationModalOpen(true)
  }

  const handleBrandChange = selectedOption => {
    setSelectedBrand(selectedOption)
    let updatedProduct = {
      ...editingProduct,
      goods_brand_id: selectedOption.value,
      goods_brand_name: selectedOption.label,
    }
    setEditingProduct(updatedProduct)

    // 対象商品ID配列、対象ブランドID,ブランド名、削除フラグ、登録フラグ
    dispatch(
      fetchGoodsBrandRegData(
        [updatedProduct.goods_id],
        updatedProduct.goods_brand_id,
        null,
        null,
        null
      )
    )
  }

  const selectAllGoods = () => {
    const newSelectedGoods = {}
    filteredGoods.forEach(good => {
      newSelectedGoods[good.goods_id] = true
    })
    setSelectedGoods(newSelectedGoods)
  }

  const selectFilteredGoods = () => {
    const newSelectedGoods = {}
    filteredGoods.forEach(good => {
      newSelectedGoods[good.goods_id] = true
    })
    setSelectedGoods(newSelectedGoods)
  }

  const deselectAllGoods = () => {
    setSelectedGoods({})
  }

  useEffect(() => {
    // dispatch(fetchGoodsBrandData());
    dispatch(fetchGraphData())
  }, [dispatch])

  const handleSearchCriteriaChange = e => {
    setSearchCriteria(e.target.value)
    selectFilteredGoods()
  }

  const handleSelectProduct = product => {
    setEditingProduct(product)
  }

  useEffect(() => {
    if (selectedBrand) {
      setEditingProduct(null)
    }
  }, [selectedBrand])

  const confirmBrandChange = () => {
    // 一括ブランド登録処理
    const targetGoods = Object.keys(selectedGoods).filter(
      goodId => selectedGoods[goodId]
    )
    // 対象商品ID配列、対象ブランドID,ブランド名、削除フラグ、登録フラグ
    dispatch(
      fetchGoodsBrandRegData(targetGoods, newGood.brand, null, null, null)
    )

    setIsConfirmationModalOpen(false)
    setIsModalOpen(false)
  }

  const handleBrandDelete = brandId => {
    // ブランド削除処理

    // 対象商品ID配列、対象ブランドID,ブランド名、削除フラグ、登録フラグ
    dispatch(fetchGoodsBrandRegData([], brandId, null, true, null))
  }

  const handleBrandEditStart = brand => {
    setEditingBrand(brand)
    setEditedBrandName(brand.label)
  }

  const handleBrandEditChange = event => {
    setEditedBrandName(event.target.value)
  }

  const handleBrandEditCommit = () => {
    //　ブランド名変更処理

    // 対象商品ID配列、対象ブランドID,ブランド名、削除フラグ、登録フラグ
    dispatch(
      fetchGoodsBrandRegData(
        null,
        editingBrand.value,
        editedBrandName,
        null,
        null
      )
    )

    setEditingBrand(null)
  }

  const handleNewBrandChange = event => {
    setNewBrand(event.target.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="商材カテゴリー"
          />
          <Row>
            <Col xs={12}>
              <Card className="bg-secondary">
                <CardBody>
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 9999, // 必要に応じて、他の要素より前面に表示されるように設定します
                      }}
                    >
                      <div style={{ color: "white", fontSize: "2em" }}>
                        Loading...
                      </div>
                    </div>
                  )}
                  <h4 className="card-title mb-4">一括カテゴリー登録</h4>
                  <p>
                    選択したカテゴリーで、チェックボックスが押されている商品のカテゴリー登録を行います。
                  </p>
                  <Select
                    className="form-control my-3"
                    options={goodsBrandOptions}
                    onChange={handleNewGoodChange}
                  />
                  <Button
                    color="primary"
                    onClick={handleBrandAllRegistration}
                    style={{ float: "right" }}
                  >
                    登録
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>
                    商材一覧
                    <Button
                      color="primary"
                      onClick={() => setIsModalOpen(true)}
                      style={{ float: "right" }}
                    >
                      +
                    </Button>
                  </CardTitle>
                  <Input
                    type="text"
                    onChange={handleSearchCriteriaChange}
                    placeholder="検索..."
                  />
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={e =>
                              e.target.checked
                                ? selectAllGoods()
                                : deselectAllGoods()
                            }
                          />
                        </th>
                        <th style={{ width: "40%" }}>商品名</th>
                        <th style={{ width: "50%" }}>カテゴリー</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredGoods.map(product => (
                        <tr key={product.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={!!selectedGoods[product.goods_id]}
                              onChange={() =>
                                setSelectedGoods({
                                  ...selectedGoods,
                                  [product.goods_id]:
                                    !selectedGoods[product.goods_id],
                                })
                              }
                            />
                          </td>
                          <td>{product.goods_name}</td>
                          <td>
                            {editingProduct === product ? (
                              <Select
                                options={goodsBrandOptions}
                                onChange={handleBrandChange}
                                value={selectedBrand}
                              />
                            ) : product.goods_brand_id ? (
                              product.goods_brand_name ||
                              (
                                goodsBrandOptions.find(
                                  brand =>
                                    brand.value === product.goods_brand_id
                                ) || {}
                              ).label ||
                              "No Brand"
                            ) : (
                              "No Brand"
                            )}
                            <Button
                              onClick={() => handleSelectProduct(product)}
                            >
                              選択
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
          >
            <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
              商材ブランド登録画面
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="brandName">ブランド名</Label>
                  <Input
                    type="text"
                    id="brandName"
                    onChange={handleNewBrandChange}
                    value={newBrand}
                  />
                </FormGroup>
                <Button color="primary" onClick={handleBrandRegistration}>
                  登録
                </Button>
              </Form>
              <hr />
              <h5>ブランドリスト</h5>
              <ListGroup>
                {goodsBrandOptions.map(brand => (
                  <ListGroupItem key={brand.value}>
                    {editingBrand === brand ? (
                      <div>
                        <Input
                          type="text"
                          value={editedBrandName}
                          onChange={handleBrandEditChange}
                        />
                        <Button color="primary" onClick={handleBrandEditCommit}>
                          変更
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => setEditingBrand(null)}
                        >
                          キャンセル
                        </Button>
                      </div>
                    ) : (
                      <div>
                        {brand.label}
                        <Button
                          color="link"
                          onClick={() => handleBrandEditStart(brand)}
                        >
                          編集
                        </Button>
                        <Button
                          color="link"
                          onClick={() => handleBrandDelete(brand.value)}
                        >
                          削除
                        </Button>
                      </div>
                    )}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={isConfirmationModalOpen}
            toggle={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}
          >
            <ModalHeader
              toggle={() =>
                setIsConfirmationModalOpen(!isConfirmationModalOpen)
              }
            >
              ブランド変更確認
            </ModalHeader>
            <ModalBody>
              <p>以下の商品のブランドを変更しますか？</p>
              <ul>
                {Object.keys(selectedGoods)
                  .filter(goodId => selectedGoods[goodId])
                  .map(goodId => {
                    const good = goods.find(
                      good => good.goods_id === Number(goodId)
                    )
                    if (!good) {
                      return <li key={goodId}></li>
                    }
                    return <li key={goodId}>{good.goods_name}</li>
                  })}
              </ul>
              <p>変更するブランド: {newGood.name}</p>
              <div>
                <Button
                  color="danger"
                  onClick={() => setIsConfirmationModalOpen(false)}
                >
                  キャンセル
                </Button>
                <Button color="primary" onClick={confirmBrandChange}>
                  OK
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GoodList
