import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fetchCamerasData, updateCameras } from "../../store/cameras/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

const Cameras = () => {
  const [selectedVendingMachine, setSelectedVendingMachine] = useState(null)
  const [modal, setModal] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [note, setNote] = useState("")

  const [tempStartDate, setTempStartDate] = useState(null)
  const [tempEndDate, setTempEndDate] = useState(null)
  const [tempLoc, setTempLoc] = useState(null)
  const [tempNote, setTempNote] = useState("")
  const [statusFilter, setStatusFilter] = useState("稼働中及び未割当")

  const dispatch = useDispatch()

  const camerasData = useSelector(state => state.camerasReducer.camerasData)
  const isLoading = useSelector(state => state.camerasReducer.loading)

  const cameras =
    camerasData && camerasData.data ? camerasData.data.results : []
  const locList =
    camerasData && camerasData.data ? camerasData.data.results2 : []

  const [sortField, setSortField] = useState("receive_end")
  const [sortDirection, setSortDirection] = useState("desc") // "asc" or "desc"

  const [sortedCameras, setSortedCameras] = useState([])

  const [isFormValid, setIsFormValid] = useState(false)

  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    dispatch(fetchCamerasData())
  }, [dispatch])

  useEffect(() => {
    setSortedCameras(cameras)
  }, [cameras])

  useEffect(() => {
    if (tempStartDate && tempEndDate && tempLoc && tempLoc.value) {
      setIsFormValid(true)
    } else {
      setIsFormValid(false)
    }
  }, [tempStartDate, tempEndDate, tempLoc])

  useEffect(() => {
    if (cameras.length > 0) {
      sortCameras(sortField, sortDirection)
    }
  }, [cameras, sortField, sortDirection, statusFilter])

  const handleVendingMachineClick = vendingMachine => {
    let locLabel = null

    if (
      vendingMachine.loc_code &&
      vendingMachine.loc_name &&
      vendingMachine.loc_code !== "null" &&
      vendingMachine.loc_name !== "null"
    ) {
      locLabel = `${vendingMachine.loc_code} - ${vendingMachine.loc_name}`
    }

    setSelectedVendingMachine(vendingMachine)
    setTempStartDate(
      vendingMachine.start_at ? new Date(vendingMachine.start_at) : null
    )
    setTempEndDate(
      vendingMachine.end_at ? new Date(vendingMachine.end_at) : null
    )
    setTempLoc({ label: locLabel, value: vendingMachine.loc_id })
    setTempNote(vendingMachine.note || "")
    setModal(true)
  }

  const handleIpcChange = selectedOption => {
    setTempLoc(selectedOption)
  }

  const handleConfirmChanges = () => {
    const updatedData = {
      camera_id: selectedVendingMachine.camera_id,
      uuid: selectedVendingMachine.uuid,
      loc_id: tempLoc.value,
      start_at: tempStartDate,
      end_at: tempEndDate,
      note: tempNote,
      deleted: 0,
    }

    // State更新
    setSelectedVendingMachine(updatedData)
    setStartDate(tempStartDate)
    setEndDate(tempEndDate)
    setNote(tempNote)

    // APIへの連携
    dispatch(updateCameras(updatedData))

    toggle()
  }

  const toggle = () => setModal(!modal)

  function formatDate(dateString) {
    if (!dateString) {
      return "未設定"
    }
    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      return "未設定"
    }
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return `${year}年${month}月${day}日`
  }

  const sortCameras = (field, direction) => {
    const sorted = [...cameras].sort((a, b) => {
      if (
        field === "receive_start" ||
        field === "receive_end" ||
        field === "start_at" ||
        field === "end_at"
      ) {
        const dateA = new Date(a[field])
        const dateB = new Date(b[field])
        if (dateA < dateB) {
          return direction === "asc" ? -1 : 1
        }
        if (dateA > dateB) {
          return direction === "asc" ? 1 : -1
        }
        return 0
      } else {
        if (a[field] < b[field]) {
          return direction === "asc" ? -1 : 1
        }
        if (a[field] > b[field]) {
          return direction === "asc" ? 1 : -1
        }
        return 0
      }
    })
    let updatedCameras = [...sorted]
    if (statusFilter) {
      if (statusFilter === "稼働中及び未割当") {
        updatedCameras = updatedCameras.filter(
          camera => camera.status === "稼働中" || camera.status === "未割当"
        )
      } else {
        updatedCameras = updatedCameras.filter(
          camera => camera.status === statusFilter
        )
      }
    }
    setSortedCameras(updatedCameras)
  }

  const handleSort = field => {
    const direction =
      sortField === field && sortDirection === "asc" ? "desc" : "asc"
    setSortField(field)
    setSortDirection(direction)
    sortCameras(field, direction)
  }

  const mappedOptions = [
    { value: 9999999999999, label: "<無し>" },
    ...locList.map(loc => ({
      value: loc.loc_id,
      label:
        loc.loc_code && loc.loc_name && loc.loc_code !== "null"
          ? `${loc.loc_code} - ${loc.loc_name}`
          : "",
    })),
  ]

  const filteredCameras = sortedCameras.filter(camera => {
    const query = searchQuery.toLowerCase()

    const checkValue = value => {
      if (!value) return false
      return value.toString().toLowerCase().includes(query)
    }

    return (
      checkValue(camera.status) ||
      checkValue(camera.uuid) ||
      checkValue(camera.loc_name) ||
      checkValue(camera.start_at) ||
      checkValue(camera.end_at) ||
      checkValue(camera.note)
    )
  })

  const styleRecived = (status, date) => {
    /* もしstatusが"稼働中"で、受信終了時刻が現在時刻よりも1時間以上前ならば、太字の赤色にする */
    if (status !== "稼働中") {
      return {}
    }
    const now = new Date()
    const receive_end = new Date(date)
    const diff = now.getTime() - receive_end.getTime()
    const diff_hour = diff / (1000 * 60 * 60)
    if (diff_hour > 1) {
      return { fontWeight: "bold", color: "red" }
    }
    return {}
  }

  const handleDelete = camera => {
    if (window.confirm("本当に削除しますか？")) {
      const updatedData = {
        camera_id: camera.camera_id,
        uuid: camera.uuid,
        deleted: 1, // 仮に削除を表すプロパティとして`deleted`を追加
      }

      dispatch(updateCameras(updatedData))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="Prime-Eye 管理"
          />
          <ErrorMessage />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <CardTitle>Prime-Eye 管理</CardTitle>
                  <LoadingOverlay isLoading={isLoading} />
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flexGrow: 0.5, marginRight: "10px" }}>
                      <input
                        type="text"
                        placeholder="カメラを検索..."
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div style={{ display: "flex", maxWidth: "300px" }}>
                      <button
                        onClick={() =>
                          setStatusFilter(prevFilter =>
                            prevFilter === "稼働中" ? "" : "稼働中"
                          )
                        }
                        className={`btn btn-sm ${
                          statusFilter === "稼働中"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                        style={{ flex: 1, marginRight: "5px", flexShrink: 0 }}
                      >
                        稼働中
                      </button>
                      <button
                        onClick={() =>
                          setStatusFilter(prevFilter =>
                            prevFilter === "稼働中及び未割当"
                              ? ""
                              : "稼働中及び未割当"
                          )
                        }
                        className={`btn btn-sm ${
                          statusFilter === "稼働中及び未割当"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                        style={{ flex: 1, marginRight: "5px", flexShrink: 0 }}
                      >
                        稼働中及び未割当
                      </button>
                      <button
                        onClick={() =>
                          setStatusFilter(prevFilter =>
                            prevFilter === "未割当" ? "" : "未割当"
                          )
                        }
                        className={`btn btn-sm ${
                          statusFilter === "未割当"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                        style={{ flex: 1, marginRight: "5px", flexShrink: 0 }}
                      >
                        未割当
                      </button>
                      <button
                        onClick={() =>
                          setStatusFilter(prevFilter =>
                            prevFilter === "終了" ? "" : "終了"
                          )
                        }
                        className={`btn btn-sm ${
                          statusFilter === "終了"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                        style={{ flex: 1, flexShrink: 0 }}
                      >
                        終了
                      </button>
                    </div>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }}></th>
                        <th
                          onClick={() => handleSort("status")}
                          style={{ width: "5%" }}
                        >
                          状態
                        </th>
                        <th
                          onClick={() => handleSort("uuid")}
                          style={{ width: "20%" }}
                        >
                          UUID
                        </th>
                        <th
                          onClick={() => handleSort("receive_start")}
                          style={{ width: "10%" }}
                        >
                          受信開始
                        </th>
                        <th
                          onClick={() => handleSort("receive_end")}
                          style={{ width: "10%" }}
                        >
                          受信終了
                        </th>
                        <th
                          onClick={() => handleSort("loc_name")}
                          style={{ width: "20%" }}
                        >
                          ロケーション名
                        </th>
                        <th
                          onClick={() => handleSort("start_at")}
                          style={{ width: "10%" }}
                        >
                          開始日時
                        </th>
                        <th
                          onClick={() => handleSort("end_at")}
                          style={{ width: "10%" }}
                        >
                          終了日時
                        </th>
                        <th
                          onClick={() => handleSort("note")}
                          style={{ width: "15%" }}
                        >
                          備考欄
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCameras.map((machine, index) => (
                        <tr key={`${machine.uuid}-${index}`}>
                          <td>
                            <Button
                              type="button"
                              color="danger"
                              onClick={() => handleDelete(machine)}
                            >
                              削除
                            </Button>
                          </td>
                          <td
                            onClick={() => handleVendingMachineClick(machine)}
                          >
                            {machine.status}
                          </td>
                          <td
                            onClick={() => handleVendingMachineClick(machine)}
                          >
                            {machine.uuid}
                          </td>
                          <td
                            onClick={() => handleVendingMachineClick(machine)}
                          >
                            {machine.receive_start}
                          </td>
                          <td
                            style={styleRecived(
                              machine.status,
                              machine.receive_end
                            )}
                            onClick={() => handleVendingMachineClick(machine)}
                          >
                            {machine.receive_end}
                          </td>
                          <td
                            onClick={() => handleVendingMachineClick(machine)}
                          >
                            {machine.loc_name}
                          </td>
                          <td
                            onClick={() => handleVendingMachineClick(machine)}
                          >
                            {machine.start_at}
                          </td>
                          <td
                            onClick={() => handleVendingMachineClick(machine)}
                          >
                            {machine.end_at}
                          </td>
                          <td
                            onClick={() => handleVendingMachineClick(machine)}
                          >
                            {machine.note}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Modal isOpen={modal} toggle={toggle}>
                    <div className="modal-body">
                      <h5>開始日 </h5>
                      <DatePicker
                        selected={tempStartDate}
                        onChange={setTempStartDate}
                        showTimeSelect
                        dateFormat="yyyy/MM/dd HH:mm"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                      />
                      <h5>終了日 </h5>
                      <DatePicker
                        selected={tempEndDate}
                        onChange={setTempEndDate}
                        showTimeSelect
                        dateFormat="yyyy/MM/dd HH:mm"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                      />
                      <h5>ロケーション </h5>
                      <Select
                        options={mappedOptions}
                        value={tempLoc}
                        onChange={handleIpcChange}
                        isSearchable
                      />
                      <h5>備考</h5>
                      <textarea
                        className="form-control"
                        rows="4"
                        value={tempNote}
                        onChange={e => setTempNote(e.target.value)}
                        placeholder="備考を入力してください..."
                      ></textarea>
                    </div>
                    <div className="modal-footer">
                      <Button
                        type="button"
                        onClick={handleConfirmChanges}
                        color="primary"
                      >
                        決定
                      </Button>
                      <Button
                        type="button"
                        onClick={toggle}
                        color="secondary"
                        data-dismiss="modal"
                      >
                        閉じる
                      </Button>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Cameras
