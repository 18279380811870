import { get, put, del } from "../api_helper"
import * as url from "../url_helper"

const getCameras = async () => {
  const response = await get(url.GET_CAMERAS)
  return { data: response }
}

const putCameras = async (
  cameraId,
  uuid,
  locId,
  startAt,
  endAt,
  note,
  deleted
) => {
  const startAtStr = formatDate(startAt)
  const endAtStr = formatDate(endAt)

  const response = await put(url.PUT_CAMERAS, {
    cameraId: cameraId,
    uuid: uuid,
    locId: locId,
    startAt: startAtStr,
    endAt: endAtStr,
    note: note,
    deleted: deleted,
  })

  return { data: response }
}

const deleteCameras = async (userId, locId) => {
  const queryParams = new URLSearchParams()
  queryParams.append("user_id", userId)
  queryParams.append("loc_id", locId)
  const response = await del(`${url.DEL_IPCS}?${queryParams.toString()}`)

  return { data: response }
}

function formatDate(date) {
  if (!date) {
    return ""
  }

  if (typeof date === "string") {
    return date
  }

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")

  return `${year}-${month}-${day} ${hours}:${minutes}`
}

export { getCameras, putCameras, deleteCameras }
