import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"
import {
  fetchGoods,
  addGood,
  updateGood,
  deleteGood,
  registerSurprise,
  unregisterSurprise,
} from "../../store/goods/actions"
import {
  fetchWarehouses,
  addWarehouse,
  updateWarehouse,
  deleteWarehouse,
  fetchWarehouseStock,
  addWarehouseStock,
  updateWarehouseStock,
  deleteWarehouseStock,
} from "../../store/warehouse/actions"
import {
  fetchVehicles,
  addVehicle,
  updateVehicle,
  deleteVehicle,
  fetchVehicleStock,
  addVehicleStock,
  updateVehicleStock,
  deleteVehicleStock,
} from "../../store/vehicle/actions"

// 各モーダルコンポーネントのインポート
import AddItemModal from "./modal/AddItemModal"
import UpdateItemModal from "./modal/UpdateItemModal"
import ProcurementModal from "./modal/ProcurementModal"
import ItemStatusModal from "./modal/ItemStatusModal"
import AddItemWarehouseStockModal from "./modal/AddItemWarehouseStockModal"
import UpdateItemWarehouseStockModal from "./modal/UpdateItemWarehouseStockModal"
import AddItemVehicleStockModal from "./modal/AddItemVehicleStockModal"
import UpdateItemVehicleStockModal from "./modal/UpdateItemVehicleStockModal"
import InstructionModal from "./modal/InstructionModal" // 説明ウィンドウのインポート
import SurpriseModal from "./modal/SurpriseModal" // サプライズウィンドウのインポート

const Goods = () => {
  const dispatch = useDispatch()

  const [selectedWarehouseId, setSelectedWarehouseId] = useState("")
  const [selectedDeliveryVehicleId, setSelectedDeliveryVehicleId] = useState("")
  const [inventoryData, setInventoryData] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [sortConfig, setSortConfig] = useState({
    key: "goods_id",
    direction: "ascending",
  })

  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false)
  const [
    isAddItemWarehouseStockModalOpen,
    setIsAddItemWarehouseStockModalOpen,
  ] = useState(false)
  const [isAddItemVehicleStockModalOpen, setIsAddItemVehicleStockModalOpen] =
    useState(false)
  const [isSurpriseModalOpen, setIsSurpriseModalOpen] = useState(false)
  const [selectedSurpriseItem, setSelectedSurpriseItem] = useState(null)
  const [potentialSurpriseItems, setPotentialSurpriseItems] = useState([])

  const [newItem, setNewItem] = useState({
    itemId: "",
    name: "",
    stock_count: 0,
    supplierId: "",
    procurementPrice: 0,
    isSurprise: false,
    additionalItemIds: [],
    searchQuery: "",
  })

  const warehouses = useSelector(state => state.warehouseReducer.warehouses)
  const isLoading1 = useSelector(state => state.warehouseReducer.loading)
  const goods = useSelector(state => state.GoodsReducer.goods)
  const isLoading2 = useSelector(state => state.GoodsReducer.loading)

  const warehouseStocks =
    useSelector(state => state.warehouseReducer.warehouseStocks) || []
  const vehicles = useSelector(state => state.vehicleReducer.vehicles) || []
  const vehicleStock =
    useSelector(state => state.vehicleReducer.vehicleStock) || []

  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false) // 説明ウィンドウの状態管理

  useEffect(() => {
    const commonData = JSON.parse(localStorage.getItem("commonData"))
    if (commonData && commonData.showInstructionModal === false) {
      setIsInstructionModalOpen(false)
    } else {
      setIsInstructionModalOpen(true)
    }
  }, [])

  const handleHideInstructionModal = () => {
    setIsInstructionModalOpen(false)
    const commonData = JSON.parse(localStorage.getItem("commonData")) || {}
    commonData.showInstructionModal = false
    localStorage.setItem("commonData", JSON.stringify(commonData))
  }

  useEffect(() => {
    dispatch(fetchGoods())
    dispatch(fetchWarehouses())
    dispatch(fetchVehicles())
  }, [dispatch])

  const getSortedItems = (items, config) => {
    if (!config.key) return items

    const sortedItems = [...items].sort((a, b) => {
      if (a[config.key] < b[config.key])
        return config.direction === "ascending" ? -1 : 1
      if (a[config.key] > b[config.key])
        return config.direction === "ascending" ? 1 : -1
      return 0
    })

    return sortedItems
  }

  const handleSearch = event => {
    setSearchQuery(event.target.value)
  }

  const handleSort = key => {
    let direction = "ascending"
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending"
    }
    setSortConfig({ key, direction })
  }

  const filteredGoods =
    goods?.data?.results?.filter(item =>
      item.goods_name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || []

  const sortedGoods = getSortedItems(filteredGoods, sortConfig)

  const [isProcurementModalOpen, setIsProcurementModalOpen] = useState(false)
  const [selectedItemForProcurement, setSelectedItemForProcurement] =
    useState(null)
  const [procurementInfo, setProcurementInfo] = useState({
    supplier: "",
    leadTime: "",
    contact: "",
    stock_count: 0,
  })

  // 仕入れ先のテストデータ
  const testSuppliers = [
    {
      id: "SP001",
      name: "Supplier 1",
      leadTime: 5,
      contact: "contact1@example.com",
    },
    {
      id: "SP002",
      name: "Supplier 2",
      leadTime: 7,
      contact: "contact2@example.com",
    },
  ]

  // 在庫アイテムの追加用モーダルを開く
  const handleOpenAddItemModal = () => {
    setIsAddItemModalOpen(true)
  }

  const handleOpenAddItemWarehouseStockModal = () => {
    setIsAddItemWarehouseStockModalOpen(true)
  }

  const handleOpenAddItemVehicleStockModal = () => {
    setIsAddItemVehicleStockModalOpen(true)
  }

  const handleSurpriseClick = item => {
    setSelectedSurpriseItem(item)
    const companyItems = goods?.data?.results?.filter(
      g => g.company_name === item.company_name && g.goods_id !== item.goods_id
    )
    setPotentialSurpriseItems(companyItems)
    setIsSurpriseModalOpen(true)
  }

  const handleRegisterSurprise = surpriseItemId => {
    if (selectedSurpriseItem) {
      dispatch(registerSurprise(selectedSurpriseItem.goods_id, surpriseItemId))
    }
    setIsSurpriseModalOpen(false)
  }

  const handleUnregisterSurprise = itemId => {
    dispatch(unregisterSurprise(itemId))
  }

  // 在庫アイテムの追加用モーダルを閉じる
  const handleCloseAddItemModal = () => {
    setIsAddItemModalOpen(false)
  }

  // 新規商品追加モーダルを開く・閉じる関数
  const toggleAddItemModal = () => setIsAddItemModalOpen(!isAddItemModalOpen)

  // 新規商品追加処理
  const handleAddNewItem = () => {
    // 新規商品追加のロジックをここに実装
    toggleAddItemModal()
  }

  const displayedInventory =
    inventoryData.find(
      data => data.warehouseId === parseInt(selectedWarehouseId)
    )?.items || []

  // 仕入れモーダルを開く関数の更新
  const handleOpenProcurementModal = item => {
    setSelectedItemForProcurement(item)
    setIsProcurementModalOpen(true)
    // 仕入れ情報の初期化（例：最初の仕入れ先を選択）
    setProcurementInfo({
      supplier: testSuppliers[0].id, // 最初の仕入れ先のID
      leadTime: testSuppliers[0].leadTime,
      contact: testSuppliers[0].contact,
    })
  }

  // 仕入れ先が変更された時の処理
  const handleSupplierChange = event => {
    const selectedSupplierId = event.target.value
    const supplier = testSuppliers.find(s => s.id === selectedSupplierId)
    setProcurementInfo({
      ...procurementInfo,
      supplier: supplier.id,
      leadTime: supplier.leadTime,
      contact: supplier.contact,
    })
  }

  // 仕入れモーダルを開く・閉じる関数
  const toggleProcurementModal = () =>
    setIsProcurementModalOpen(!isProcurementModalOpen)

  const handleOpenItemStatusModal = itemId => {
    const item = itemStatusTestData.find(item => item.itemId === itemId)
    if (item && item.statuses) {
      setSelectedItemStatus(item.statuses) // 複数のステータス情報をセット
      setIsItemStatusModalOpen(true)
    } else {
      // 該当する商品IDのステータス情報が見つからない場合、空の配列を設定
      setSelectedItemStatus([])
      setIsItemStatusModalOpen(true)
    }
  }

  // 商品ステータスのテストデータ
  const itemStatusTestData = [
    {
      itemId: "IT001",
      statuses: [
        {
          itemName: "A商品",
          class: 1,
          status: "仕入れ済み",
          stock_count: 100,
          date: "2023/03/10",
          details: "A仕入先から仕入れ完了",
        },
        {
          itemName: "A商品",
          class: 1,
          status: "仕入れ中",
          stock_count: 100,
          date: "2023/03/20",
          details: "A仕入先から仕入れ中",
        },
        {
          itemName: "A商品",
          class: 2,
          status: "戻し中",
          stock_count: 3,
          date: "2023/03/21",
          details: "A自販機から戻し中",
        },
        {
          itemName: "A商品",
          class: 3,
          status: "補充済",
          stock_count: -13,
          date: "2023/03/21",
          details: "A自販機へ補充",
        },
        {
          itemName: "A商品",
          class: 3,
          status: "補充済",
          stock_count: -10,
          date: "2023/03/21",
          details: "A自販機へ補充",
        },
      ],
    },
    // IT002, IT003など他の商品IDに対するステータスも同様に追加可能
  ]

  const [isItemStatusModalOpen, setIsItemStatusModalOpen] = useState(false)
  const [selectedItemStatus, setSelectedItemStatus] = useState(null)

  // 商品ステータスモーダルを閉じる関数
  const handleCloseItemStatusModal = () => {
    setIsItemStatusModalOpen(false)
  }

  const [selectedItems, setSelectedItems] = useState([])

  // CSV出力機能
  const handleExportCSV = () => {
    const selectedInventory = inventoryData
      .find(data => data.warehouseId.toString() === selectedWarehouseId)
      ?.items.filter(item => selectedItems.includes(item.itemId))

    if (!selectedInventory || selectedInventory.length === 0) {
      alert("エクスポートするアイテムを選択してください。")
      return
    }

    // CSV形式の文字列を生成
    let csvContent = "data:text/csv;charset=utf-8,"
    csvContent += "商品コード,名前,数量\n" // ヘッダー
    selectedInventory.forEach(item => {
      csvContent += `${item.itemId},${item.name},${item.stock_count}\n`
    })

    // CSVファイルとしてダウンロード
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "inventory_export.csv")
    document.body.appendChild(link) // Firefoxで必要
    link.click()
    document.body.removeChild(link)
  }

  // 選択のトグル
  const toggleSelectedItem = itemId => {
    setSelectedItems(prev =>
      prev.includes(itemId)
        ? prev.filter(id => id !== itemId)
        : [...prev, itemId]
    )
  }

  // 全選択/全解除
  const toggleSelectAll = () => {
    if (selectedItems.length === displayedInventory.length) {
      setSelectedItems([])
    } else {
      setSelectedItems(displayedInventory.map(item => item.itemId))
    }
  }

  // 倉庫選択時の挙動
  const handleSelectWarehouse = id => {
    setSelectedWarehouseId(id)
    setSelectedDeliveryVehicleId("") // 配送車選択をリセット

    dispatch(fetchWarehouseStock(id)) // 倉庫の在庫情報を取得
  }

  // 配送車選択時の挙動
  const handleSelectDeliveryVehicle = id => {
    setSelectedDeliveryVehicleId(id)
    setSelectedWarehouseId("") // 倉庫選択をリセット

    // 配送車の在庫情報を取得
    dispatch(fetchVehicleStock(id))
  }

  // 選択されているボタンを管理するための状態
  const [selectedOption, setSelectedOption] = useState("goodsList")

  // 配送車リストを表示するためのコンポーネント
  const DeliveryVehicleList = ({ deliveryVehicles }) => (
    <Table className="table">
      <thead>
        <tr>
          <th>#</th>
          <th>配送車ID</th>
          <th>名前</th>
          <th>容量</th>
        </tr>
      </thead>
      <tbody>
        {deliveryVehicles.map((vehicle, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{vehicle.id}</td>
            <td>{vehicle.name}</td>
            <td>{vehicle.capacity}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )

  // 配送車補充モーダルの状態管理
  const [isReplenishmentModalOpen, setIsReplenishmentModalOpen] =
    useState(false)
  const [replenishmentItem, setReplenishmentItem] = useState(null)

  // 配送車補充モーダルを開く関数
  const handleOpenReplenishmentModal = () => {
    if (selectedItems.length !== 1) {
      alert("補充するためには、一つの商品を選択してください。")
      return
    }
    const selectedItem = inventoryData
      .find(({ warehouseId }) => warehouseId.toString() === selectedWarehouseId)
      ?.items.find(item => item.itemId === selectedItems[0])

    setReplenishmentItem({ ...selectedItem, stock_count: 0 }) // 補充数量を0で初期化
    setIsReplenishmentModalOpen(true)
  }

  // 配送車補充の実行
  const handleReplenishItem = () => {
    // ここに補充ロジックを実装
    alert(
      `補充された商品: ${replenishmentItem.name}, 数量: ${replenishmentItem.stock_count}, 配送車: ${selectedDeliveryVehicleId}`
    )
    setIsReplenishmentModalOpen(false)
  }

  // 選択されたサプライズ商品の一覧を表示するコンポーネント
  const SelectedItemsDisplay = ({ items, inventory }) => {
    return (
      <div>
        <h5>選択されたサプライズ商品:</h5>
        <ul>
          {items.map(itemId => (
            <li key={itemId}>
              {inventory.find(item => item.itemId === itemId)?.name ||
                "商品名不明"}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  // 配送車補充モーダル
  const ReplenishmentModal = ({ isOpen, toggle, item, onReplenish }) => (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>配送車補充</ModalHeader>
      <ModalBody>
        {item && (
          <>
            <FormGroup>
              <Label for="itemSelect">商品名</Label>
              <Input id="itemSelect" type="text" value={item.name} readOnly />
            </FormGroup>
            <FormGroup>
              <Label for="stock_count">補充数量</Label>
              <Input
                id="stock_count"
                type="number"
                value={item.stock_count}
                onChange={e =>
                  setReplenishmentItem({
                    ...item,
                    stock_count: parseInt(e.target.value, 10) || 0,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="deliveryVehicleSelect">配送車選択</Label>
              <Input
                id="deliveryVehicleSelect"
                name="select"
                type="select"
                value={selectedDeliveryVehicleId}
                onChange={e => setSelectedDeliveryVehicleId(e.target.value)}
              >
                <option value="">配送車を選択してください</option>
                {deliveryVehiclesTestData.map(vehicle => (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.name} - {vehicle.capacity}キャパ
                  </option>
                ))}
              </Input>
            </FormGroup>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          キャンセル
        </Button>
        <Button color="primary" onClick={onReplenish}>
          実行
        </Button>
      </ModalFooter>
    </Modal>
  )

  // 商品更新用モーダルの開閉管理
  const [isUpdateItemModalOpen, setIsUpdateItemModalOpen] = useState(false)
  const [selectedItemForUpdate, setSelectedItemForUpdate] = useState(null)

  const handleOpenUpdateItemModal = item => {
    setSelectedItemForUpdate(item)
    setIsUpdateItemModalOpen(true)
  }

  const handleUpdateItem = updatedItem => {
    dispatch(updateGood(updatedItem)) // 商品更新のアクションをディスパッチ
  }

  const [
    isUpdateItemWarehouseStockModalOpen,
    setIsUpdateItemWarehouseStockModalOpen,
  ] = useState(false)
  const [
    selectedItemForUpdateWarehouseStock,
    setSelectedItemForUpdateWarehouseStock,
  ] = useState(null)

  const handleOpenUpdateItemWarehouseStockModal = item => {
    setSelectedItemForUpdateWarehouseStock(item)
    setIsUpdateItemWarehouseStockModalOpen(true)
  }

  const [
    isUpdateItemVehicleStockModalOpen,
    setIsUpdateItemVehicleStockModalOpen,
  ] = useState(false)
  const [
    selectedItemForUpdateVehicleStock,
    setSelectedItemForUpdateVehicleStock,
  ] = useState(null)

  const handleOpenUpdateItemVehicleStockModal = item => {
    setSelectedItemForUpdateVehicleStock(item)
    setIsUpdateItemVehicleStockModalOpen(true)
  }

  // サプライズ商品推測ボタンのハンドル
  const handleSurpriseGuess = () => {
    if (searchQuery !== "ガチャ") {
      setSearchQuery("ガチャ")
    }
    if (searchQuery === "ガチャ") {
      setSearchQuery("")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="main"
            title="管理"
            breadcrumbItem="商品管理"
          />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1 || isLoading2} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>倉庫/配送車リスト</CardTitle>
                  <FormGroup>
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio3"
                        autoComplete="off"
                        checked={selectedOption === "goodsList"}
                        onChange={() => setSelectedOption("goodsList")}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="btnradio3"
                      >
                        商品一覧
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        checked={selectedOption === "warehouse"}
                        onChange={() => setSelectedOption("warehouse")}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="btnradio1"
                      >
                        倉庫選択
                      </label>

                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autoComplete="off"
                        checked={selectedOption === "deliveryVehicle"}
                        onChange={() => setSelectedOption("deliveryVehicle")}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="btnradio2"
                      >
                        配送車選択
                      </label>
                    </div>
                    {selectedOption === "warehouse" ? (
                      <Input
                        id="warehouseSelect"
                        name="select"
                        type="select"
                        value={selectedWarehouseId}
                        onChange={e => handleSelectWarehouse(e.target.value)}
                        disabled={selectedOption === "goodsList"}
                      >
                        <option value="">倉庫を選択してください</option>
                        {warehouses.map(warehouse => (
                          <option
                            key={warehouse.warehouse_id}
                            value={warehouse.warehouse_id}
                          >
                            {warehouse.warehouse_code} - {warehouse.address}
                          </option>
                        ))}
                      </Input>
                    ) : selectedOption === "deliveryVehicle" ? (
                      <Input
                        id="deliveryVehicleSelect"
                        name="select"
                        type="select"
                        value={selectedDeliveryVehicleId}
                        onChange={e =>
                          handleSelectDeliveryVehicle(e.target.value)
                        }
                        disabled={selectedOption === "goodsList"}
                      >
                        <option value="">配送車を選択してください</option>
                        {vehicles.map(vehicle => (
                          <option
                            key={vehicle.vehicle_id}
                            value={vehicle.vehicle_id}
                          >
                            {vehicle.vehicle_name}
                          </option>
                        ))}
                      </Input>
                    ) : null}
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {selectedOption === "goodsList" && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle>商品一覧</CardTitle>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="商品名で検索"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </FormGroup>
                    <div
                      className="mb-2"
                      style={{ display: "flex", gap: "10px" }}
                    >
                      <Button
                        color="success"
                        onClick={handleOpenAddItemModal}
                        style={{ width: "200px" }}
                      >
                        新規商品登録
                      </Button>
                      <Button
                        color="info"
                        onClick={handleSurpriseGuess}
                        style={{ width: "200px" }}
                      >
                        サプライズ商品推測
                      </Button>
                    </div>
                    <Table className="table">
                      <thead>
                        <tr>
                          <th onClick={() => handleSort("surprise")}>
                            ｻﾌﾟﾗｲｽﾞ
                          </th>
                          <th onClick={() => handleSort("goods_no")}>
                            商品番号
                          </th>
                          <th onClick={() => handleSort("goods_name")}>
                            商品名
                          </th>
                          <th onClick={() => handleSort("surprise_goods_name")}>
                            サプライズ商品の中身
                          </th>
                          <th onClick={() => handleSort("goods_brand_name")}>
                            カテゴリー
                          </th>
                          <th onClick={() => handleSort("company_name")}>
                            会社
                          </th>
                          <th>操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedGoods.map(item => (
                          <tr key={item.goods_id}>
                            <td>{item.is_surprise ? "〇" : ""}</td>
                            <td>{item.goods_no}</td>
                            <td>{item.goods_name}</td>
                            <td>{item.surprise_goods_name}</td>
                            <td>{item.goods_brand_name}</td>
                            <td>{item.company_name}</td>
                            <td>
                              <Button
                                color="warning"
                                size="sm"
                                onClick={() =>
                                  item && handleOpenUpdateItemModal(item)
                                }
                              >
                                修正
                              </Button>{" "}
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => handleSurpriseClick(item)}
                                disabled={item.is_surprise === 2}
                              >
                                サプライズ
                              </Button>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {selectedWarehouseId && selectedOption === "warehouse" && (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle>商品一覧</CardTitle>
                    <Button
                      color="success"
                      onClick={handleOpenAddItemWarehouseStockModal}
                      className="mb-2"
                      style={{ width: "200px" }}
                    >
                      新規商品登録
                    </Button>
                    <Button
                      color="warning"
                      onClick={handleExportCSV}
                      className="mb-2 ms-2"
                      disabled={selectedItems.length === 0}
                      style={{ width: "200px" }}
                    >
                      仕入履歴CSV出力
                    </Button>
                    <Button
                      color="primary"
                      onClick={handleOpenReplenishmentModal}
                      className="mb-2 ms-2"
                      disabled={selectedItems.length === 0}
                      style={{ width: "200px" }}
                    >
                      配送車補充
                    </Button>
                    <Table className="table">
                      <thead>
                        <tr>
                          <th>
                            <Input
                              type="checkbox"
                              checked={
                                selectedItems.length ===
                                displayedInventory.length
                              }
                              onChange={toggleSelectAll}
                            />
                          </th>
                          <th>ID</th>
                          <th>サプライズ商品</th>
                          <th>商品コード</th>
                          <th>販売商品名</th>
                          <th>数量</th>
                          <th>操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        {warehouseStocks.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <Input
                                type="checkbox"
                                checked={selectedItems.includes(
                                  item.is_surprise
                                )}
                                onChange={() =>
                                  toggleSelectedItem(item.is_surprise)
                                }
                              />
                            </td>
                            <td
                              onClick={() =>
                                handleOpenItemStatusModal(item.itemId)
                              }
                            >
                              {item.goods_id}
                            </td>
                            <td
                              onClick={() =>
                                handleOpenItemStatusModal(item.itemId)
                              }
                            >
                              {item.is_surprise === 1 ? "〇" : ""}
                            </td>
                            <td
                              onClick={() =>
                                handleOpenItemStatusModal(item.itemId)
                              }
                            >
                              {item.goods_no}
                            </td>
                            <td
                              onClick={() =>
                                handleOpenItemStatusModal(item.itemId)
                              }
                            >
                              {item.goods_name}
                            </td>
                            <td
                              onClick={() =>
                                handleOpenItemStatusModal(item.itemId)
                              }
                            >
                              {item.stock_count}
                            </td>
                            <td>
                              <Button
                                color="warning"
                                size="sm"
                                onClick={() =>
                                  handleOpenUpdateItemWarehouseStockModal(item)
                                }
                              >
                                修正
                              </Button>{" "}
                              <Button
                                color="info"
                                size="sm"
                                onClick={() => handleOpenProcurementModal(item)}
                              >
                                仕入
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {selectedDeliveryVehicleId &&
            selectedOption === "deliveryVehicle" && (
              <Row>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>商品一覧</CardTitle>
                      <Button
                        color="success"
                        onClick={handleOpenAddItemVehicleStockModal}
                        className="mb-2"
                        style={{ width: "200px" }}
                      >
                        新規商品登録
                      </Button>
                      <Button
                        color="warning"
                        onClick={handleExportCSV}
                        className="mb-2 ms-2"
                        disabled={selectedItems.length === 0}
                        style={{ width: "200px" }}
                      >
                        仕入履歴CSV出力
                      </Button>
                      <Button
                        color="primary"
                        onClick={handleOpenReplenishmentModal}
                        className="mb-2 ms-2"
                        disabled={selectedItems.length === 0}
                        style={{ width: "200px" }}
                      >
                        配送車補充
                      </Button>
                      <Table className="table">
                        <thead>
                          <tr>
                            <th>
                              <Input
                                type="checkbox"
                                checked={
                                  selectedItems.length ===
                                  displayedInventory.length
                                }
                                onChange={toggleSelectAll}
                              />
                            </th>
                            <th>ID</th>
                            <th>サプライズ商品</th>
                            <th>商品コード</th>
                            <th>販売商品名</th>
                            <th>数量</th>
                            <th>操作</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vehicleStock.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Input
                                  type="checkbox"
                                  checked={selectedItems.includes(
                                    item.is_surprise
                                  )}
                                  onChange={() =>
                                    toggleSelectedItem(item.is_surprise)
                                  }
                                />
                              </td>
                              <td
                                onClick={() =>
                                  handleOpenItemStatusModal(item.itemId)
                                }
                              >
                                {item.goods_id}
                              </td>
                              <td
                                onClick={() =>
                                  handleOpenItemStatusModal(item.itemId)
                                }
                              >
                                {item.is_surprise === 1 ? "〇" : ""}
                              </td>
                              <td
                                onClick={() =>
                                  handleOpenItemStatusModal(item.itemId)
                                }
                              >
                                {item.goods_no}
                              </td>
                              <td
                                onClick={() =>
                                  handleOpenItemStatusModal(item.itemId)
                                }
                              >
                                {item.goods_name}
                              </td>
                              <td
                                onClick={() =>
                                  handleOpenItemStatusModal(item.itemId)
                                }
                              >
                                {item.stock_count}
                              </td>
                              <td>
                                <Button
                                  color="warning"
                                  size="sm"
                                  onClick={() =>
                                    handleOpenUpdateItemVehicleStockModal(item)
                                  }
                                >
                                  修正
                                </Button>{" "}
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={() =>
                                    handleOpenProcurementModal(item)
                                  }
                                >
                                  仕入
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          {/* 新規商品追加モーダル */}
          <AddItemModal
            isOpen={isAddItemModalOpen}
            toggle={() => setIsAddItemModalOpen(!isAddItemModalOpen)}
            newItem={newItem}
            setNewItem={setNewItem}
          />
          {/* 倉庫在庫の新規商品追加モーダル */}
          <AddItemWarehouseStockModal
            isOpen={isAddItemWarehouseStockModalOpen}
            toggle={() =>
              setIsAddItemWarehouseStockModalOpen(
                !isAddItemWarehouseStockModalOpen
              )
            }
            goods={goods.data && goods.data.results ? goods.data.results : []}
            selectedWarehouseId={selectedWarehouseId}
          />
          {/* 商品在庫更新モーダル */}
          <UpdateItemWarehouseStockModal
            isOpen={isUpdateItemWarehouseStockModalOpen}
            toggle={() =>
              setIsUpdateItemWarehouseStockModalOpen(
                !isUpdateItemWarehouseStockModalOpen
              )
            }
            item={selectedItemForUpdateWarehouseStock}
            onUpdate={handleUpdateItem}
            warehouses={warehouses}
            selectedWarehouseId={selectedWarehouseId}
          />
          {/* 配送車在庫の新規商品追加モーダル */}
          <AddItemVehicleStockModal
            isOpen={isAddItemVehicleStockModalOpen}
            toggle={() =>
              setIsAddItemVehicleStockModalOpen(!isAddItemVehicleStockModalOpen)
            }
            goods={goods.data && goods.data.results ? goods.data.results : []}
            selectedDeliveryVehicleId={selectedDeliveryVehicleId}
          />
          {/* 配送車在庫更新モーダル */}
          <UpdateItemVehicleStockModal
            isOpen={isUpdateItemVehicleStockModalOpen}
            toggle={() =>
              setIsUpdateItemVehicleStockModalOpen(
                !isUpdateItemVehicleStockModalOpen
              )
            }
            item={selectedItemForUpdateVehicleStock}
            onUpdate={handleUpdateItem}
            vehicles={vehicles}
            selectedDeliveryVehicleId={selectedDeliveryVehicleId}
          />
          {/* 仕入れ情報モーダル */}
          <ProcurementModal
            isOpen={isProcurementModalOpen}
            toggle={toggleProcurementModal}
            selectedItemForProcurement={selectedItemForProcurement}
            procurementInfo={procurementInfo}
            handleSupplierChange={handleSupplierChange}
          />
          {/* 商品ステータス情報モーダルの呼び出し */}
          <ItemStatusModal
            isOpen={isItemStatusModalOpen}
            toggle={handleCloseItemStatusModal}
            itemStatuses={selectedItemStatus}
          />
          {/* 配送車補充モーダル */}
          <ReplenishmentModal
            isOpen={isReplenishmentModalOpen}
            toggle={() =>
              setIsReplenishmentModalOpen(!isReplenishmentModalOpen)
            }
            item={replenishmentItem}
            onReplenish={handleReplenishItem}
          />
          {/* 商品更新モーダル */}
          <UpdateItemModal
            isOpen={isUpdateItemModalOpen}
            toggle={() => setIsUpdateItemModalOpen(!isUpdateItemModalOpen)}
            item={selectedItemForUpdate}
            onUpdate={handleUpdateItem}
          />
          {/* 説明ウィンドウ */}
          <InstructionModal
            isOpen={isInstructionModalOpen}
            toggle={() => setIsInstructionModalOpen(false)}
            handleHide={handleHideInstructionModal} // モーダルを非表示にするための関数を渡す
          />
          {/* サプライズウィンドウ */}
          <SurpriseModal
            isOpen={isSurpriseModalOpen}
            toggle={() => setIsSurpriseModalOpen(false)}
            item={selectedSurpriseItem}
            potentialItems={potentialSurpriseItems}
            onRegisterSurprise={handleRegisterSurprise}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Goods
